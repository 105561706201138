<template>
  <div class="section-clients">
    <div class="container">
      <div class="middle-title"><slot name="title"></slot></div>
      <div class="caption"><slot name="subtitle"></slot></div>
      <div class="items">
        <layout-own-carousel :xs-count="3" :count="7" gap="5em">
          <slot></slot>
        </layout-own-carousel>
      </div>
      <div class="link">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import layoutOwnCarousel from '@/components/layout/layout-own-carousel'
export default {
  components: {
    layoutOwnCarousel
  }
}
</script>

<style lang="scss" scoped>
.section-clients {
  text-align: center;
  padding-bottom: 5em;
}
.caption {
  width: 33em;
  max-width: 100%;
  position: relative;
  margin: 1em auto;
}
.items {
  margin-top: 3em;
}
.link {
  margin-top: 3em;
}
</style>
