<template>
  <div class="section-banner">
    <div class="container">
      <layout-own-carousel>
        <slot></slot>
      </layout-own-carousel>
    </div>
  </div>
</template>

<script>
import layoutOwnCarousel from '@/components/layout/layout-own-carousel'
export default {
  components: {
    layoutOwnCarousel
  }
}
</script>

<style lang="scss" scoped>
.section-banner {
  --size: 100%;
  padding-bottom: 5em;
  min-height: MIN(680px, 60vw);
}
</style>
