<template>
  <div class="section-products">
    <div class="container">
      <div class="great-title"><slot name="title"></slot></div>
      <div class="subtitle"><slot name="subtitle"></slot></div>
      <!--  -->
      <div class="products">
        <slot></slot>
      </div>
      <div class="button-wrapper">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.section-products {
  padding-bottom: 5em;
}
.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5em 3em;
  margin-top: 4em;
}
.button-wrapper {
  text-align: center;
  margin-top: 5em;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px 15px;
  }
}
</style>
