<template>
  <div class="section-testimonials">
    <div class="container">
      <div class="wrapper">
        <div class="great-title"><slot name="title"></slot></div>
        <div class="subtitle"><slot name="subtitle"></slot></div>
        <div class="testimonials">
          <layout-own-carousel ref="slider" :xs-count="1" :count="3" gap="2em">
            <slot></slot>
          </layout-own-carousel>
          <div class="arrows abs">
            <div class="arrow left" @click="$refs.slider.prev()">
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.469673 6.53033C0.176773 6.23744 0.176773 5.76256 0.469673 5.46967L5.24264 0.696699C5.53554 0.403806 6.01041 0.403806 6.3033 0.696699C6.59619 0.989593 6.59619 1.46447 6.3033 1.75736L2.06066 6L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53554 11.5962 5.24264 11.3033L0.469673 6.53033ZM91 6.75H1V5.25H91V6.75Z"
                  fill="black"
                />
              </svg>
            </div>
            <div class="arrow right" @click="$refs.slider.next()">
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M90.5303 6.53033C90.8232 6.23744 90.8232 5.76256 90.5303 5.46967L85.7574 0.696699C85.4645 0.403806 84.9896 0.403806 84.6967 0.696699C84.4038 0.989593 84.4038 1.46447 84.6967 1.75736L88.9393 6L84.6967 10.2426C84.4038 10.5355 84.4038 11.0104 84.6967 11.3033C84.9896 11.5962 85.4645 11.5962 85.7574 11.3033L90.5303 6.53033ZM0 6.75H90V5.25H0V6.75Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import layoutOwnCarousel from '@/components/layout/layout-own-carousel'
export default {
  components: {
    layoutOwnCarousel
  }
}
</script>

<style lang="scss" scoped>
.section-testimonials {
  padding-bottom: 5em;
}
.wrapper {
  background: var(--bg);
  text-align: center;
  padding: 4em;
}
.testimonials {
  margin-top: 3em;
  position: relative;
}
.arrows {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120%;
  left: -10%;
}
.arrow {
  cursor: pointer;
  pointer-events: all;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .arrows {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .arrows {
    display: none;
  }
  .wrapper {
    padding: 2em;
  }
}
</style>
