<template>
  <div class="section-last-posts">
    <div class="container">
      <div class="wrapper">
        <div class="middle-title"><slot name="title"></slot></div>
        <div class="subtitle"><slot name="subtitle"></slot></div>
        <div class="items">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.section-last-posts {
  padding-bottom: 5em;
}
.wrapper {
  background: var(--bg);
  padding: 4em;
}
.items {
  margin-top: 3.5em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875em;
}
@media screen and (max-width: 767px) {
  .wrapper {
    padding: 2em;
  }
  .items {
    display: block;
    & > * {
      display: block;
      margin-bottom: 3em;
    }
  }
}
</style>
