<template>
  <div class="section-features">
    <div class="container">
      <div class="great-title"><slot name="title"></slot></div>
      <div class="subtitle"><slot name="subtitle"></slot></div>
      <div class="features">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.section-features {
  padding-bottom: 7em;
}
.features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5em;
  margin-top: 4em;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -2em;
    left: 0;
    width: 100%;
    height: 1px;
    background: #fff;
  }
  .feature-icon {
    background: var(--bg);
    width: 3.75em;
    height: 3.75em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    & + * {
      margin-bottom: 0.5em;
    }
  }
  .caption {
    opacity: 0.5;
  }
  .feature {
    position: relative;
    &:after {
      content: '';
      width: 1px;
      height: 100%;
      right: -1em;
      top: 0;
      position: absolute;
      background: currentColor;
      opacity: 0.1;
    }
    &:before {
      content: '';
      width: 85%;
      height: 1px;
      bottom: -2em;
      left: 0;
      position: absolute;
      background: currentColor;
      opacity: 0.1;
    }
    &:nth-child(4n + 4) {
      &:after {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .features {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
